import React from 'react';
import AddListItemModal from './AddListItemModal';
import './AddListItemModal.css';


class AddListItemButton extends React.Component {

  constructor() {
    super();
    this.state = {
      show: false
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

//need to pass props down all the way to addlistitem

  render() {
      return(
          <div id="AddListButton">
            <button id="additembutton" type="button" onClick={this.showModal}>Add item</button>
            <AddListItemModal listname={this.props.listname} show={this.state.show} handleClose={this.hideModal} setpullswitch={this.props.setpullswitch} >
            </AddListItemModal>
          </div>
          
      )
  }
}

export default AddListItemButton;