import './AddListNoteModal.css';
import AddListNote from './AddListNote';

const AddListNoteModal = ({ handleClose, show, children, listname,  }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="addlistmodal">
      <h1>Add a description</h1>
        {children}
        <AddListNote listname={listname} />
            <button id="submitbuttons" type="button" onClick={handleClose} >
            Cancel
            </button>
      </section>
    </div>
  );
};

export default AddListNoteModal;