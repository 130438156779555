import React from 'react';
import AddListNoteModal from './AddListNoteModal';
import './AddListNoteModal.css';


class AddListNoteButton extends React.Component {

  constructor() {
    super();
    this.state = {
      show: false
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };



  render() {
      return(
          <div id="AddListButton">
            <button id="collectionbutton" type="button" onClick={this.showModal}>Add description</button>
            <AddListNoteModal listname={this.props.listname} show={this.state.show} handleClose={this.hideModal} setloopswitch={this.props.setloopswitch}  >
            </AddListNoteModal>
          </div>
          
      )
  }
}

export default AddListNoteButton;