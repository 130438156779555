import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

export const AddList = () => {

    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [mylist, setMylist] = useState('');
    const navigate = useNavigate(); 


    function handleSubmit(event){
        event.preventDefault();
        axios.post('/api/trythis', {userid: user.sub, mylist})
        .then(function(response) {
            if (response.data) {
                navigate({
                    //this is hacky for now
                    pathname: '/onboard'
                })
            }
        })

    }


    function handleOnChange(event){
        if (event.target.name === 'mylist'){
            setMylist(event.target.value);
        };
    };

  return (
      <div id="submitform">   
        <form onSubmit={handleSubmit}>
            <input id="listinput" onChange={handleOnChange} placeholder="Name your collection" name="mylist" value={mylist}/>
            <input id="submitbuttons" type='submit' value='Add'/>
        </form>
      </div>
  )
};


export default AddList;