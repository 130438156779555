import React from 'react';
import AddListModal from './AddListModal';
import './AddListModal.css';


class AddListButton extends React.Component {

  constructor() {
    super();
    this.state = {
      show: false
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };



  render() {
      return(
          <div id="AddListButton">
            <button id="collectionbutton" type="button" onClick={this.showModal}>Add collection</button>
            <AddListModal show={this.state.show} handleClose={this.hideModal} >
            </AddListModal>
          </div>
          
      )
  }
}

export default AddListButton;