import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

export const AddListItem = (props) => {

    const { user } = useAuth0();
    const [linktext, setlinktext] = useState('');
    const [linkdest, setlinkdest] = useState('');
    const [category, setcategory] = useState('');
    const [note, setnote] = useState('');
    const setpullswitch = props.setpullswitch;
    const listname = props.listname;
    const navigate = useNavigate(); 


    function handleSubmit(event){
        event.preventDefault();
        axios.post('/api/addlistitem', {userid: user.sub, listname, linktext, linkdest, note, category})
        .catch(function(error) { 
            console.log(error);
          })
          .then(function(response) {
            if (response.data) {
                
                navigate({
                    //this is hacky for now
                    pathname: '/onboard'
                })
            }
        })
        

    }

    function handleOnChangetext(event){
        if (event.target.name === 'linktext' ){
            setlinktext(event.target.value);
        };
    };

    function handleOnChangedest(event){
        if (event.target.name === 'linkdest' ){
            setlinkdest(event.target.value);
        };
    };

    function handleOnChangecategory(event){
        if (event.target.name === 'category' ){
            setcategory(event.target.value);
        };
    };

    function handleOnChangenote(event){
        if (event.target.name === 'note' ){
            setnote(event.target.value);
        };
    };

  return (
      <div id="submitform">   
        <form onSubmit={handleSubmit}>
            <input id="listinput" onChange={handleOnChangetext} placeholder="Add list item" name="linktext" value={linktext}/>
            <input id="listinput" onChange={handleOnChangedest} placeholder="Add link" name="linkdest" value={linkdest}/>
            <input id="listinput" onChange={handleOnChangecategory} placeholder="Add category" name="category" value={category}/>
            <input id="listinput" onChange={handleOnChangenote} placeholder="Add note" name="note" value={note}/>
            <input id="submitbuttons" type='submit' value='Add'/>
        </form>
      </div>
  )
};


export default AddListItem;