import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import { Link, NavLink, useParams } from 'react-router-dom';
import ExternalPullTop from "./ExternalPullTop";
import ListScroll from "../ListScroll/ListScroll";



export const External = () => {

    const { externalname } = useParams();
    const [myuser, setUser] = useState([]);
    const [mylists, setMylists] = useState([]);
    const [flipswitch, setflipswitch] = useState(false);

    useEffect(() => {
      
      const getUser = async () => {
          try {
              axios.post('/api/finduser', {externalname})
              .then(function(response) {
                setUser(response.data);
                setflipswitch(true);
              })
    
          } catch(e) {
              console.log(e.message);
          }
      }
      getUser();



//issue is that it's not sending the right array object for myuser to backend
  if (flipswitch==true) {      
const getexternallist = async () => {
          axios.post('/api/getlists', {userid: myuser[0]?.username}) //works with 'google-oauth2|107450026911496219479'
          .then(function(response) {
            setMylists(response.data)
            //setflipswitch(true); //had this idea and it worked
          }).catch(function(error) { 
            console.log(error);
          });
        } 
      getexternallist();} 
     },[flipswitch]);   





     
     const thislist = mylists.toReversed().map((list) =>  {
      const showlistitems = list.items.map((item) => {
        return(
          <div>
          <div className="ListItem">
          <p><a href={item.linkdest} target="_blank" rel="noopener noreferrer">{item.linktext}</a></p>
          <p>{item.category}</p>
          <p>{item.date.slice(0, 10)}</p>
          </div>
          <p id="itemnote">{item.note}</p> 
          </div>
        )
       })

       //i think the following one is to use for the styling like the sample
        return(
//commit

          <div className="Profile">
            <div className="ProfileLeft" id={list.listname}> {/*new for anchorlink*/}
              <h1>{list.listname}</h1>
              <p>{list.listnote}</p>
            </div>
            <div className="ProfileRight">
              {showlistitems}
            </div>
          </div>
     
        )
       })

  return (
        <div className="WholeProfile">
          <div className="TopPart">
            <ExternalPullTop />
            <ListScroll mylists={mylists}/>
          </div>
          {thislist}
          {/*<h1>{(myuser[0]) ? myuser[0].username : "no"}</h1>*/}
        </div>
  )


  }


export default External;