import React from 'react';
import './Nolidge.css';
import AddList from '../AddList/AddList';
import GetList from '../GetList/GetList';
import Sidebar from '../Sidebar/Sidebar';
import AddListButton from '../AddList/AddListButton';
import { User } from '@auth0/auth0-react';



class Nolidge extends React.Component {
    render() {
        return(
            <div className='Nolidge' style={{backgroundImage: `url("/TopImage.png")`}}>
                <div className='Top'>
                    <div id="Lefthold">
                        <h1>Ben Kutner</h1>
                        <h2>I'm a composer who likes physics, programming and AI. 
                            Executive and Artistic Director of New Parnassus. Nonprofit fundraiser.
                        </h2>
                        {/*<AddList />*/}
                        <AddListButton />
                    </div>
                    <div className='right'>
                        <button>Watch</button>
                    </div>  
                </div>
                <div className='Lower'>
                    <Sidebar />
                    <GetList />
                    {/*<div className='Card'>
                  
                        <h2>NY Phil review</h2>
                        <h3>I was at this show</h3>
                        <p>...</p>
                    </div>
                    <div className='Card'>
                        <h2>Han Dynasty</h2>
                        <h3>Best restaurant in LES</h3>
                        <p>...</p>
                    </div>
                    <div className='Card'>
                        <h2>Astoria Beer and Cheese</h2>
                        <h3>Just went</h3>
                        <p>...</p>
                    </div>
                    <div className='Card'>
                        <h2><a href="https://en.wikipedia.org/wiki/The_Bear_(TV_series)" target="_blank" rel="noopener noreferrer">The Bear</a></h2>
                        <h3>Watching it now</h3>
                        <p>...</p>
                    </div>
                    <div className='Card'>
                        <h2>NY Phil review</h2>
                        <h3>I was at this show</h3>
                        <p>...</p>
                    </div>
                    <div className='Card'>
                        <h2>Han Dynasty</h2>
                        <h3>Best restaurant in LES</h3>
                        <p>...</p>
                    </div>
                    <div className='Card'>
                        <h2>Astoria Beer and Cheese</h2>
                        <h3>Just went</h3>
                        <p>...</p>
                    </div>
                    <div className='Card'>
                        <h2><a href="https://en.wikipedia.org/wiki/The_Bear_(TV_series)" target="_blank" rel="noopener noreferrer">The Bear</a></h2>
                        <h3>Watching it now</h3>
                        <p>...</p>
        </div>*/}
                </div>
                

            </div>

            
        )
    }
}


export default Nolidge;