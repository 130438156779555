import './OnboardAddExternalNameModal.css';
import OnboardAddExternalName from './OnboardAddExternalName';

const OnboardAddExternalNameModal = ({ handleClose, show, children, listname }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="externalnamemodal">
        {children}
        <p id="changeurl">Create URL</p>
        <OnboardAddExternalName />
        <div id="lowerbutton">
            <button id="thisbutton" type="button" onClick={handleClose} >
            Cancel
            </button>
        </div>
      </section>
    </div>
  );
};

export default OnboardAddExternalNameModal;