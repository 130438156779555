import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

export const AddListNote = (props) => {

    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [listnote, setlistnote] = useState('');
    const listname = props.listname;
    const navigate = useNavigate(); 


    function handleSubmit(event){
        event.preventDefault();
        axios.post('/api/addlistnote', {userid: user.sub, listname, listnote})
        .then(function(response) {
            if (response.data) {
                navigate({
                    //this is hacky for now
                    pathname: '/onboard'
                })
            }
        })
    }
    function handleOnChange(event){
        if (event.target.name === 'listnote'){
            setlistnote(event.target.value);
        };
    };

  return (
      <div id="submitform">   
        <form onSubmit={handleSubmit}>
            <input id="listinput" onChange={handleOnChange} placeholder="Describe the collection" name="listnote" value={listnote}/>
            <input id="submitbuttons" type='submit' value='Add'/>
        </form>
      </div>
  )
};


export default AddListNote;