import React from 'react';
import './Profile.css';
import ProfileTop from './ProfileTop';
import PullLists from './PullLists.js';
import AddListButton from '../AddList/AddListButton';
import AddNameButton from '../AddName/AddNameButton';


class Profile extends React.Component {
    
    render() {
        return(
                <div className='WholeProfile'>
                  <ProfileTop />
                  <PullLists />
                </div>

            
        )
    }
}


export default Profile;