import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import './Sidebar.css';



export const Sidebar = () => {

    const { user } = useAuth0();
    const [mylists, setMylists] = useState([]);
  
  
    useEffect(() => {
      
      const getMylists = async () => {
              await axios.post('/getlists', {userid: user.sub})
              .then(function(response) {
                setMylists(response.data)
              }).catch(function(error) { //this and the follwoing lines seemed to fix the 'sub' runtime error
                console.log(error);
              });
      }
      getMylists();
    
     },[user?.sub]);    
     
     const thislist = mylists.map((list) =>  {
  
          return(
            <div>
            <div>
              <a href={"#"+list.listname}>{list.listname}</a>
              </div>
            </div>
          )
         })
     

  return (
      <div>   
        <p>{thislist}</p>
      </div>
  )
};


export default Sidebar;