import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

export const EditListItem = (props) => {


    //following is hacky for the default value
    //because on clicking the form, doesn't dissapear
    const { user } = useAuth0();
    const linktext = props.linktext;
    const [newlinktext, setnewlinktext] = useState(linktext);
    const linkdest = props.linkdest;
    const [newlinkdest, setnewlinkdest] = useState(linkdest);
    const category = props.category;
    const [newcategory, setnewcategory] = useState(category);
    const note = props.note;
    const [newnote, setnewnote] = useState(note);
    const listname = props.listname;
    const navigate = useNavigate(); 


    function handleSubmit(event){
        event.preventDefault();
        
        axios.post('/api/editlistitem', {
            userid: user.sub, 
            linktext, 
            newlinktext, 
            listname, 
            newcategory, 
            newlinkdest, 
            newnote,
            category,
            note,
            linkdest
        })
        .catch(function(error) { 
            console.log(error);
          })    .then(function(response) {
            if (response.data) {
                navigate({
                    //this is hacky for now
                    pathname: '/onboard'
                })
            }
        })
      
        

    }

    function onClick (event){
        event.preventDefault();
        axios.post('/api/deletelistitem', {
            userid: user.sub, 
            linktext, 
            listname
        })
        .catch(function(error) { 
            console.log(error);
          })    .then(function(response) {
            if (response.data) {
                navigate({
                    //this is hacky for now
                    pathname: '/onboard'
                })
            }
        })
        
    }

    function handleOnChangetext(event){
        if (event.target.name === 'newlinktext' ){
            setnewlinktext(event.target.value);
        };
    };

    function handleOnChangedest(event){
        if (event.target.name === 'newlinkdest' ){
            setnewlinkdest(event.target.value);
        };
    };

    function handleOnChangecategory(event){
        if (event.target.name === 'newcategory' ){
            setnewcategory(event.target.value);
        };
    };

    function handleOnChangenote(event){
        if (event.target.name === 'newnote' ){
            setnewnote(event.target.value);
        };
    };

  return (
      <div id="submitform">   
        <form onSubmit={handleSubmit}>
            <input id="listinput" onChange={handleOnChangetext} placeholder={linktext} name="newlinktext" value={newlinktext} />
            <input id="listinput" onChange={handleOnChangedest} placeholder={linkdest} name="newlinkdest" value={newlinkdest}/>
            <input id="listinput" onChange={handleOnChangecategory} placeholder={category} name="newcategory" value={newcategory} />
            <input id="listinput" onChange={handleOnChangenote} placeholder={note} name="newnote" value={newnote}/>
            <input id="submitbuttons" type='submit' value='Edit'/>
            <button onClick={onClick} id="submitbuttons">Delete</button>
        </form>
      </div>
  )
};
//comment for commit

export default EditListItem;