import React from 'react';
import './Frontpage.css';
import FrontpageJoinButton from '../FrontpageJoin/FrontpageJoin';




class Frontpage extends React.Component {
    render() {
        return(
            <div className='Frontpage'>
                <div className="FrontpageTop">
                    <h1>knowroom</h1>
                    <h2>A showroom for your thoughts.</h2>
                    <p>Carve out your own corner of the internet. 
                        Share links to the content you enjoy. Build
                        collections of links united by topics. 
                    </p>
                    <FrontpageJoinButton />
                </div>
                
            </div>

            
        )
    }
}


export default Frontpage;