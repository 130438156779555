import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import AddExternalname from "../AddExternalName/AddExternalName";
import { Link, NavLink, useParams } from 'react-router-dom';
import './External.css'

export const ExternalPullTop = () => {

const { externalname } = useParams();
  const [me, setMe] = useState([]);

  useEffect(() => {
      
    const findUser = async () => {
      axios.post('/api/pullexternaluser', {externalname: externalname}) 
      .then(function(response) {
        setMe(response.data)
      }).catch(function(error) { 
        console.log(error);
      });
        }
      findUser();

 },[null]);     

 const thisuser = me.map((meitem) =>  {
  
    return(
      <div id="ExternalTopSection">
        <h1>{meitem.name}</h1>
        <h3>{`@${meitem.externalname}`}</h3>
        <h3>{meitem.description}</h3>
      </div>
    )})
   

  
  return (
        <div className="ProfileTop">
          {thisuser}
        </div>
  )
};


export default ExternalPullTop;