import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import './ListScroll.css';



export const ListScroll = (props) => {

    const [ show, setShow ] = useState(false)
    const mylists = props.mylists;


//using toReversed() on both this and External seems to have worked
    const thislist = mylists.toReversed().map((list) =>  {
          return(
            <div className="linkitem">
                <a href={`#${list.listname}`} onClick={()=>setShow(! show)}>{list.listname}</a>
            </div>
          )
         })
  
return(
    <div className="ListScroll">
        <button id="ScrollButton" onClick={()=>setShow(! show)}>Collections</button>
    <div className={`${show? 'use':'hidden'}`}>
        {thislist}
        <button id="takebutton" onClick={()=>setShow(! show)}>close</button>
    </div>
    </div>
)

}
export default ListScroll;