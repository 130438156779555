import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import './AddNameModal.css';

export const AddName = () => {

    const { user } = useAuth0();
    const [name, setName] = useState('');


    function handleSubmit(event){
        //event.preventDefault(); //added for firefox
        axios.post('/api/addname', {userid: user.sub, name})
    }

    function handleOnChange(event){
        if (event.target.name === 'name'){
            setName(event.target.value);
        };
    };

  return (
      <div>   
        <form onSubmit={handleSubmit}>
            <input onChange={handleOnChange} placeholder="Enter new name" name="name" value={name}/> ()
            <input id="submitbutton" type='submit' value='Change'/>
        </form>
      </div>
  )
};


export default AddName;