import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import { Link, NavLink, useParams } from 'react-router-dom';


export const Dashboard = () => {
  const { user } = useAuth0();
  const [mydash, setmydash] = useState({});


  useEffect(() => {
      
    //this whole thing may be uncessary because link just goes to profile
        if(user?.sub){
          const getUser = async () => {
            axios.post('/api/pulluser', {userid: user.sub}) //this seemed to fix the runtime error issue
            .then(function(response) {
              setmydash(response.data[0]) //in general, can use this on others
            }).catch(function(error) { 
              console.log(error);
            });
              }
            getUser();
       
      }
       },[user?.sub]);   
       
    if(!mydash){
  return (
        <div>
       <h1>loading</h1>
        </div>
  )} else {
    return(
        <div>
            <a href="/profile"><button>Dash</button></a>
        </div>
    )
  }
};


export default Dashboard;