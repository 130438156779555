import logo from './logo.svg';
import './App.css';
import { ReactDOM } from 'react';
import { Routes, Route, Redirect, BrowserRouter } from "react-router-dom";
import Profile from './Profile/Profile';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';
import Frontpage from './Frontpage/Frontpage';
import Nolidge from './Nolidge/Nolidge';
import AddList from './AddList/AddList';
import GetList from './GetList/GetList';
import External from './External/External';
import TestComponent from './TestComponent/TestComponent';
import Onboard from './Onboard/Onboard';

import { User } from '@auth0/auth0-react';


function App() {
  return (
    
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path='/' element={<Frontpage />} />
            <Route path='profile' element={<Profile />} />
            <Route path='me' element={<Nolidge />} />
            <Route path="/:externalname" element={<External />} />
            <Route path='testcomponent' element={<TestComponent />} />
            <Route path='onboard' element={<Onboard />} />
          </Routes>
          <Footer />

          
          
        </BrowserRouter>
      </header>
    </div>
  
  );
}

export default App;
