import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import './FrontpageJoin.css'

const FrontpageJoinButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
  <div className="FrontpageJoinButton">
  <button onClick={() => loginWithRedirect()}>Join Now</button>
  </div>
  );
};

export default FrontpageJoinButton;