import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import './Profile.css';
import AddListItemButton from "../AddListItem/AddListItemButton";
import AddListButton from "../AddList/AddListButton";
import AddListNoteButton from "../AddListNote/AddListNoteButton";
import AddList from "../AddList/AddList";
import EditListItemButton from "../EditListItem/EditListItemButton";





export const PullLists = () => {

    const [mylists, setMylists] = useState([]);
    const [switchy, setswitchy] = useState(false);
    const [pullswitch, setpullswitch] = useState(false);
    const { user } = useAuth0();
    const [myuser, setUser] = useState([]);



    useEffect(() => {
      
      if(user?.sub){
        const getUser = async () => {
          axios.post('/api/adduser', {userid: user.sub}) //this seemed to fix the runtime error issue
          .then(function(response) {
            setUser(response.data)
          }).catch(function(error) { 
            console.log(error);
          });
            }
          getUser();
      
      const getMylists = async () => {
              axios.post('/api/getlists', {userid: user.sub}) //this seemed to fix the runtime error issue
              .then(function(response) {
                setMylists(response.data)
                setswitchy(true)
              }).catch(function(error) { 
                console.log(error);
              });
      }
      getMylists();
    }
     },[user?.sub]);     

     //maybe make toReversed?
     if (switchy==true) {
     const thislist = mylists.reverse().map((list) =>  {
      const showlistitems = list.items.reverse().map((item) => {
        return(
          <div className="ListItem">
          <EditListItemButton linktext={item.linktext} />
          <p><a href={item.linkdest} target="_blank" rel="noopener noreferrer">{item.linktext}</a></p>
          <p>{item.note}</p>
          <p>{item.date.slice(0, 10)}</p>
          </div>
        )
       })

       //i think the following one is to use for the styling like the sample
        return(


          <div className="Profile">
            <div className="ProfileLeft">
              <h1>{list.listname}</h1>
              <p>{list.listnote}</p>
              <AddListNoteButton listname={list.listname} />
              {/*<button>Add notes</button>*/}
            </div>
            <div className="ProfileRight">
              {showlistitems}
            </div>
          </div>
     
        )
       })} else {
        const thislist = "test"
       }

       if (!mylists) {
        return(
          <h1>loading...</h1>
        )
       
    } else {
       return(
        <div>
          {mylists.map((list) => {
            const showlistitems = list.items.map((item) => {
              return(
                <div>
                  <div className="ListItemRow">
                <EditListItemButton listname={list.listname} linktext={item.linktext} note={item.note} category={item.category} linkdest={item.linkdest} />
                <div className="ListItem">
                <p><a href={item.linkdest} target="_blank" rel="noopener noreferrer">{item.linktext}</a></p>
                <p>{item.category}</p>
                <p>{item.date.slice(0, 10)}</p>
                </div>
                </div>
                <p id="itemnote">{item.note}</p>
                </div>
              )
             })
      
             //i think the following one is to use for the styling like the sample
              return(
      
                <div className="Profile">
                  <div className="ProfileLeft">
                    <h1>{list.listname}</h1>
                    <AddListNoteButton listname={list.listname} />
                    <p>{list.listnote}</p>
                    {/*<button>Add notes</button>*/}
                  </div>
                  <div className="ProfileRight">
                  <AddListItemButton listname={list.listname} setpullswitch={setpullswitch}/>
                    {showlistitems}
                  </div>
                </div>
           //for commit
              )
          })}
        </div>
        
        
       )
    }}



export default PullLists;