import React from 'react';
import OBAddDescriptionModal from './OBAddDescriptionModal';
import './OBAddDescriptionModal.css';



class OBAddDescriptionButton extends React.Component {

  constructor() {
    super();
    this.state = {
      show: false
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };



  render() {
      return(
          <div className="AddListButton">
            <button type="button" onClick={this.showModal}>Add a description</button>
            <OBAddDescriptionModal show={this.state.show} handleClose={this.hideModal} >
            </OBAddDescriptionModal>
          </div>
          
      )
  }
}

//git comment
export default OBAddDescriptionButton;

//change color