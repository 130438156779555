import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import './OnboardAddExternalNameModal.css';
import { useNavigate } from "react-router-dom";

export const OnboardAddExternalname = () => {

    const { user } = useAuth0();
    const [externalname, setExternalname] = useState('');
    const navigate = useNavigate(); 


    function handleSubmit(event){
        //event.preventDefault();
        axios.post('/api/addexternalname', {userid: user.sub, externalname})
        .then(
            navigate({
                pathname: '/onboard'
            }))
        


    }

    function handleOnChange(event){
        if (event.target.name === 'externalname'){
            setExternalname(event.target.value);
        };
    };

  return (
      <div>   
        <form onSubmit={handleSubmit}>
            <input onChange={handleOnChange} placeholder="New URL" name="externalname" value={externalname}/> ()
            <input id="submitbutton" type='submit' value='Create'/>
        </form>
      </div>
  )
};


export default OnboardAddExternalname;