import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import './Profile.css';
import AddExternalname from "../AddExternalName/AddExternalName";
import AddExternalNameButton from "../AddExternalName/AddExternalNameButton";
import AddDescriptionButton from "../AddDescription/AddDescriptionButton";
import AddNameButton from "../AddName/AddNameButton";
import AddListButton from "../AddList/AddListButton";
import AddList from "../AddList/AddList";
import { Link } from 'react-router-dom';


export const ProfileTop = () => {

  const { user } = useAuth0();
  const [me, setMe] = useState([]);

  useEffect(() => {
      
  if(user?.sub){
    const findUser = async () => {
      axios.post('/api/pulluser', {userid: user.sub}) 
      .then(function(response) {
        setMe(response.data)
      }).catch(function(error) { 
        console.log(error);
      });
        }
      findUser();
    }
 },[user?.sub]);     



  const thisuser = me.map((meitem) =>  {
  
    return(
      <div id="ProfileTopSection">
        <div id="TopRow">
          <h1>{meitem.name}</h1>
          <h3><Link to={`/${meitem.externalname}`}><h3>{`@${meitem.externalname}`}</h3></Link></h3>
          <h3>{meitem.description}</h3>
        </div>
        <div>
          <AddListButton />
        </div>
      </div>
    )})
   
  

//what about putting above into useeffect and using setstate to set a new variable
//or using initial value
  
  return (
        <div className="ProfileTop">
          {thisuser? thisuser : <h1>test</h1>}
        </div>
  )
};


export default ProfileTop;