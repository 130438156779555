import React from 'react';
import AddExternalNameModal from './AddExternalNameModal';
import './AddExternalNameModal.css';



class AddExternalNameButton extends React.Component {

  constructor() {
    super();
    this.state = {
      show: false
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };



  render() {
      return(
          <div className="AddListButton">
            <button type="button" onClick={this.showModal}>{this.props.externalname? this.props.externalname : "Change"}</button>
            <AddExternalNameModal show={this.state.show} handleClose={this.hideModal}>
            </AddExternalNameModal>
          </div>
          
      )
  }
}

export default AddExternalNameButton;