import React from 'react';
import './Footer.css';


class Footer extends React.Component {
    render() {
        return(
            <div className='Footer'>
                <div id='FooterTop'>
                    <h1>knowroom</h1>
                </div>
                <div id="FooterBottom"> 
                    <div id='FooterLeft'>
                        <h2>Company</h2>
                        <p>Learn</p>
                        <p>Our story</p>
                        <p>Careers</p>
                    </div>
                    <div id='FooterMid'>
                        <h2>Community</h2>
                        <p>Learn</p>
                        <p>Our story</p>
                        <p>Careers</p>
                    </div> 
                    <div id='FooterRight'>
                        <h2>Support</h2>
                        <p>Learn</p>
                        <p>Our story</p>
                        <p>Careers</p>
                    </div>
                </div>
                
               
            </div>

            
        )
    }
}


export default Footer;