import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import './AddExternalNameModal.css';

export const AddExternalname = (props) => {

    const { user } = useAuth0();
    const [externalname, setExternalname] = useState('');


    function handleSubmit(event){
        //event.preventDefault();
        axios.post('/api/addexternalname', {userid: user.sub, externalname})
        
    }

    function handleOnChange(event){
        if (event.target.name === 'externalname'){
            setExternalname(event.target.value);
        };
    };

  return (
      <div>   
        <form onSubmit={handleSubmit}>
            <input onChange={handleOnChange} placeholder="New URL" name="externalname" value={externalname}/> ()
            <input id="submitbutton" type='submit' value='Change'/>
        </form>
      </div>
  )
};


export default AddExternalname;