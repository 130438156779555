import './EditListItemModal.css';
import EditListItem from './EditListItem';

const EditListItemModal = ({ handleClose, show, children, category, note, linkdest, listname, linktext, setpullswitch }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  

  return (
    <div className={showHideClassName}>
      <section className="addlistmodal">
        {children}
        <h1>Edit the item</h1>
        <EditListItem linktext={linktext} category={category} linkdest={linkdest} note={note} listname={listname} setpullswitch={setpullswitch} />
        <div className='ModalBottom'>
            <button id="submitbuttons" type="button" onClick={handleClose} >
            Cancel
            </button>
        </div>
      </section>
    </div>
  );
};

export default EditListItemModal;