import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import AddNameButton from "../AddName/AddNameButton";
import AddExternalNameButton from "../AddExternalName/AddExternalNameButton";
import AddDescriptionButton from "../AddDescription/AddDescriptionButton";
import { useNavigate } from "react-router-dom";
import OnboardAddExternalNameButton from "../OnboardButtons/OnboardAddExternalName/OnboardAddExternalNameButton";
import OBAddDescriptionButton from "../OnboardButtons/OnboardAdddescription/OBAddDescriptionButton";
import './Onboard.css'
import OnboardAddInfo from "../OnboardAddInfo/OnboardAddInfo";

export const Onboard = () => {

    const { user } = useAuth0();
    const [me, setMe] = useState([]);
    const [hswitch, sethswitch] = useState(false);
    const navigate = useNavigate(); 


    useEffect(() => {
        
    if(user?.sub){
      const getUser = async () => {
        axios.post('/api/onboarduser', {userid: user?.sub}) 

        .catch(function(error) { 
          console.log(error);
        });
          }
        getUser();
      }

      if(user?.sub){
        const findUser = async () => {
          axios.post('/api/pulluser', {userid: user.sub}) 
          .then(function(response) {
            setMe(response.data)
          }).catch(function(error) { 
            console.log(error);
          });
            }
          findUser();
        }

   },[user?.sub]);  
   //seems to be wiping user clean every time


   function usercheck() {
    if(!me) {
        return(
            <h1>Loading...</h1>
        )
    } else {

    if(me[0]?.name==null) {
      return(
        <div className="Holder">
        <div className="welcomefirst">
      <h1>Let's get started...</h1>
      </div>
      <div className="welcomesecond">
        <OnboardAddInfo />
      </div>
      </div>
      )
    } else if(me[0]?.externalname==null) {
      return(
        <div className="welcomesecond">
        <OnboardAddExternalNameButton />
        </div>
      )
    } else if(me[0]?.description==null) {
        return(
            <div className="welcomesecond">
                <OBAddDescriptionButton />
            </div>
        )
    } else {
      navigate({
        pathname: '/profile'
    })
    }
}
  }

    

  return (
    <div className="Onboard">
      {usercheck()}
    </div>
  )
};


export default Onboard;
