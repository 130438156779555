import React from 'react';
import EditListItemModal from './EditListItemModal';
import './EditListItemModal.css';


class EditListItemButton extends React.Component {

  constructor() {
    super();
    this.state = {
      show: false
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

//need to pass props down all the way to addlistitem

  render() {
      return(
          <div id="AddListButton">
            <button id="edititembutton" type="button" onClick={this.showModal}><img src="/edit-icon.png" height="17px"  /></button>
            <EditListItemModal listname={this.props.listname} linktext={this.props.linktext} linkdest={this.props.linkdest} category={this.props.category} note={this.props.note} show={this.state.show} handleClose={this.hideModal} setpullswitch={this.props.setpullswitch} >
            </EditListItemModal>
          </div>
          
      )
  }
}

export default EditListItemButton;