import {useAuth0} from '@auth0/auth0-react';
import './AuthNavbar.css'
import LoginButton from '../Loginbutton/loginbutton';
import LogoutButton from '../LogoutButton/LogoutButton';
import SignupButton from '../SignupButton/SignupButton';
import Dashboard from '../Dashboard/Dashboard';


const AuthNavbar = () => {


  const {isAuthenticated} = useAuth0()
  return (

    
    isAuthenticated ? <div id="AuthNav"><Dashboard /> <LogoutButton /> </div> : <div id='loginsignupbuttons'><LoginButton /> <SignupButton /></div>
  )
}

export default AuthNavbar;