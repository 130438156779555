import React from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import AuthNavbar from '../AuthNavbar/AuthNavbar';


class Navbar extends React.Component {
    render() {
        return(
            <div className='Header'>
                <div id="HeaderLower">
                    <div id="HeaderLeft">
                    <Link to="/"><h1>knowroom</h1></Link>
                    </div>
                    <div id='HeaderMid'>
                        <h2>Learn</h2>
                        <h2>Blog</h2>
                        <h2>Discover</h2>
                    </div>
                    <div id='HeaderRight'>
                    <AuthNavbar />
                    </div>
                </div>
        
            </div>

            
        )
    }
}


export default Navbar;