import './AddExternalNameModal.css';
import AddExternalName from './AddExternalName';

const AddExternalNameModal = ({ handleClose, show, children, listname }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="externalnamemodal">
        {children}
        <p id="changeurl">Change URL</p>
        <AddExternalName />
        <div id="lowerbutton">
            <button id="thisbutton" type="button" onClick={handleClose}>
            Cancel
            </button>
        </div>
      </section>
    </div>
  );
};

export default AddExternalNameModal;