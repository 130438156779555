//query MongoDB with Find
//Use map to show the list
//Embed this into the 'me' page

import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import './GetList.css';
import AddListItem from "../AddListItem/AddListItem";
import AddListItemButton from "../AddListItem/AddListItemButton";


export const GetList = () => {

    const { user } = useAuth0();

    const [mylists, setMylists] = useState([]);
  
  
    useEffect(() => {
      
      const getMylists = async () => {
              axios.post('/api/getlists', {userid: user?.sub}) //this seemed to fix the runtime error issue
              .then(function(response) {
                setMylists(response.data)
              }).catch(function(error) { 
                console.log(error);
              });
      }
      getMylists();
    
     },[user?.sub]);     

     

     const thislist = mylists.map((list) =>  {
      const showlistitems = list.items.map((item) => {
        return(
          <div className="Card">
          <h2><a href={item.linkdest} target="_blank" rel="noopener noreferrer">{item.linktext}</a></h2>
          <h2>{item.linktext}</h2>
          <p>{item.note}</p>
          </div>
        )
       })

        return(
          <div>
          <div className="listtop" id={list.listname}>
            <p>{list.listname}</p>
            <AddListItemButton listname={list.listname} />
            </div>
            {showlistitems}
          </div>
        )
       })

      
        



  return (
      <div>   
        <h1>{thislist}</h1>
      </div>
  )
};


export default GetList;