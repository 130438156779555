import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import './OBAddDescriptionModal.css';
import { useNavigate } from "react-router-dom";

export const OBAddDescription = () => {

    const { user } = useAuth0();
    const [description, setDescription] = useState('');
    const navigate = useNavigate(); 


    function handleSubmit(event){
        //event.preventDefault();
        axios.post('/api/adddescription', {userid: user.sub, description})
        .then(
            navigate({
                pathname: '/onboard'
            }))

    }

    function handleOnChange(event){
        if (event.target.name === 'description'){
            setDescription(event.target.value);
        };
    };

  return (
      <div>   
        <form onSubmit={handleSubmit}>
            <input onChange={handleOnChange} placeholder="New Description" name="description" value={description}/> ()
            <input id="submitbutton" type='submit' value='Add'/>
        </form>
      </div>
  )
};


export default OBAddDescription;