import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';//new
import reportWebVitals from './reportWebVitals';

//removed strict mode from this and added auth0provider

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<Auth0Provider
    domain="dev-3vuh1rw7.us.auth0.com"
    clientId="ojktudO90qic0FpJXZbo9l3pyxLKdM25"
    //new
    useRefreshTokens={true}
    audience= "https://knowback.onrender.com" //why does this work?
    scope="read:current_user update:current_user_metadata"

    //the following appeared to fix the issue in safari
    cacheLocation= "localstorage"
    authorizationParams={{
      redirect_uri: window.location.origin + '/onboard'

      
    }}
  >
    <App />
  </Auth0Provider>,
);

//https://knowfront.onrender.com/profile
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//for commit