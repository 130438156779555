import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import './SignupButton.css'

const SignupButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
  <div className="SignupButton">
  <button onClick={() => loginWithRedirect()}>Join</button>
  </div>
  );
};

export default SignupButton;