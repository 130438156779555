import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import './OnboardAddInfo.css';
import { useNavigate } from "react-router-dom";

export const OnboardAddInfo = (props) => {

    const { user } = useAuth0();
    const [name, setName] = useState('');
    const [externalname, setExternalname] = useState('');
    const [description, setDescription] = useState('');
    const navigate = useNavigate(); 



    function handleSubmit(event){
        event.preventDefault(); 
        axios.post('/api/onboard', {userid: user.sub, name, externalname, description})
        .then(function(response) {
            if (response.data) {
                navigate({
                    pathname: '/profile'
                })
            }
        })
        
       
    }

    function handleOnChangeName(event){
        if (event.target.name === 'name'){
            setName(event.target.value);
        };
    };
    
    function handleOnChangeExternalname(event){
        if (event.target.name === 'externalname'){
            setExternalname(event.target.value);
        };
    };

    function handleOnChangeDescription(event){
        if (event.target.name === 'description'){
            setDescription(event.target.value);
        };
    };

  return (
      <div id="OnboardAddInfo">   
        <form onSubmit={handleSubmit}>
            <input id="submitbutton" onChange={handleOnChangeName} placeholder="Add name" name="name" value={name}/>
            <input id="submitbutton" onChange={handleOnChangeExternalname} placeholder="Create a URL" name="externalname" value={externalname}/>
            <input id="submitbutton" onChange={handleOnChangeDescription} placeholder="Describe yourself" name="description" value={description}/>
            <input id="submitbutton" type='submit' value='Add'/>

        </form>
      </div>
  )
};


export default OnboardAddInfo;