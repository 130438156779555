import './OBAddDescriptionModal.css';
import OBAddDescription from './OBAddDescription';

const OBAddDescriptionModal = ({ handleClose, show, children,  }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="externalnamemodal">
        {children}
        <p id="changeurl">Add description</p>
        <OBAddDescription />
        <div id="lowerbutton">
            <button id="thisbutton" type="button" onClick={handleClose} >
            Cancel
            </button>
        </div>
      </section>
    </div>
  );
};

export default OBAddDescriptionModal;