import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';


export const TestComponent = () => {

    const [switchy, setswitchy] = useState(false);
    const { user } = useAuth0();
    const [myuser, setUser] = useState([]);

    useEffect(() => {
      
      if(user?.sub){
        
        const getUser = async () => {
          axios.post('/api/pulluser', {userid: user.sub}) //this seemed to fix the runtime error issue
          .then(function(response) {
            setUser(response.data)
            setswitchy(true)
          }).catch(function(error) { 
            console.log(error);
          });
            }
          getUser();
      
      
    }
     },[user?.sub]);     

//or try if myuser!== undefined
     //shows that backend is working. problem is on frontend.
     if (!myuser) {
        return(
          <h1>loading...</h1>
        )
       
    } else {
       return(
        <h1>{myuser[0]?.username}</h1>
       )
    }

   
}



export default TestComponent;